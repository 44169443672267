<div class="overlay" *ngIf="isChatOpen || isCommentOpen" (click)="closePanels()" ></div>

<div class="main-container" [class.chat-open]="isChatOpen">


    <div class="chat-toggle-btn" (click)="toggleChat()">
      <i class="fa-solid fa-chevron-right cogeo-chat-symbole"></i>
    </div>

    <div class="cogeo-chat" [ngClass]="{'open': isChatOpen}">
      <button class="close-btn-chat" (click)="toggleChat()">
        <i class="fa-solid fa-arrow-left"></i>
      </button>
      <app-cogeo-chat [cogeoTaskId]="cogeoTaskId" [workTaskFileId]="workTaskFileId" [workTaskId]="workTaskId" [professionId]="professionId"></app-cogeo-chat>
    </div>

    <div class="comment-toggle-btn" (click)="toggleComment()">
      <i class="fa-solid fa-chevron-left comment-symbole"></i>
    </div>
    <div class="Comment" [ngClass]="{'open': isCommentOpen}">
      <button class="close-btn-comment" (click)="toggleComment()">
        <i class="fa-solid fa-arrow-right"></i>

      </button>

      <div class="comments-container">
        <table class="table-comments">
            <tbody>
                <tr *ngFor="let timeComment of comments; let i = index" [ngClass]="currentVideoTime>=timeComment.startTime && currentVideoTime<timeComment.endTime ? 'comment-line-selected' : ''">
                    <div class="single-comment-container">
                        <td class="time-comment">{{ timeComment.startTime | formatTime}}</td>
                        <td class="comment">
                            <label>{{timeComment.comment}}</label>
                        </td>
                        <td class="play-comment">
                            <button class="btn-round" (click)="goToVideoTime(timeComment.startTime)">
                                <i class="fas fa-play"></i>
                            </button>
                        </td>
                    </div>
                </tr>
            </tbody>
        </table>
    </div>
    </div>


<div class="middle-containeur">
    <app-video-player #videoPlayerComponent [videoFileParam]="videoFile"></app-video-player>
</div>

<app-generic-popin
  [isVisible]="(isModalVisible | async) ?? false"
  [title]="'Fin de l\'échange'"
  [buttonContent]="'Terminer le parcours'"
  [img]="'/assets/pictures/cogeo_avatar.png'"
  (onClose)="closePopin()">
  <div *ngIf="modalContent | async">
    {{ modalContent | async }}
  </div>
</app-generic-popin>
</div>
<app-waiting-popin></app-waiting-popin>