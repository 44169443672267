<div class="main-container">
    <div class="content-wrapper">
        <div class="upload-container">
            <div class="header">
                Ciblage
            </div>
            <div class="subHeader">
                <div class="paramsContainer">
                    <label for="approach">Approche :</label>
                    <select id="approach" [(ngModel)]="selectedApproach" >
                        <option *ngFor="let approach of approaches" [ngValue]="approach.value">
                            {{ approach.label }}
                        </option>
                    </select>
                    <select id="difficulty" [(ngModel)]="selectedDifficulty">
                        <option *ngFor="let difficulty of difficulties" [ngValue]="difficulty.value">
                            {{ difficulty.label }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="header">
                Import video
            </div>
            <div class="subHeader">
                Ajoutez votre video ici
            </div>

            <div class="drop-zone"
            [ngClass]="{ 'invalid-video': !isFileValid && selectedFile !=null }"
            (dragover)="onDragOver($event)" 
            (drop)="onDrop($event)">
                <div *ngIf="selectedFile == null || !isFileValid" (click)="selectVideo()">
                    <i class="fa-solid fa-cloud-arrow-up fa-2xl"></i>
                    <br/><br/>
                    <p>Déposer votre vidéo ou <span class="browse-link"><b>parcourir</b></span></p>
                    <p class="file-size">Taille fichier : maximum 20Go</p>
                    <p *ngIf="!isFileValid && selectedFile!=null" class="error-message">Erreur : La vidéo doit être un fichier vidéo de moins de 2 Go.</p>
                </div>
                <div class = "file-container" *ngIf="selectedFile != null && isFileValid">
                    <span class="close-btn" (click)="resetFile()">×</span>
                    <i class="fa-regular fa-file fa-2xl"></i>
                    {{selectedFile.name}}
                </div>
            </div>
            
            <p class="accepted-formats">Fichiers acceptés : .mp4, avi, ogv, mpeg, webm</p>
            
            <div class="divider"><span>OU</span></div>
            
            <div class="link-upload">
                <div class="header">
                    Import avec lien
                </div>
                <div class="link-input-container">
                    <input type="text" id="file-link" placeholder="Ajoutez le lien du fichier" [(ngModel)]="fileLink" (input)="onFileLinkInput()" />
                </div>
            </div>
        </div>
        <button class="next-btn" (click)="onNext()" [disabled]="(!selectedFile  || !isFileValid) && fileLink.trim()==''">Suivant <i class="fa-solid fa-angles-right"></i></button>
    </div>
</div>