<div class="container">
    <div class="veryBigTextBold">...et analysées</div>
    <div class="simpleIndicatorsContainer">
        <div class="simpleIndicator">
            Complétion<br><br>
            <table style="width: 100%;">
                <tr>
                    <td class="indicatorRate">{{indicators.completionRate | number:'1.0-0'}}%</td>
                    <td>
                        <div *ngIf="indicators.completionRateProgress  >= 0; else lowScore">
                            <i class="fa-solid fa-arrow-trend-up"></i>
                        </div>
                        <ng-template #lowScore>
                            <i class="fa-solid fa-arrow-trend-down"></i>
                          </ng-template>
                    </td>
                    <td class="indicatorRateProgression">
                        {{toAbsolute(indicators.completionRateProgress) | number: '1.0-2'}}%
                    </td>
                </tr>
            </table>

        </div>
        <div class="simpleIndicator">
            Réussite<br><br>
            <table style="width: 100%;">
                <tr>
                    <td class="indicatorRate">{{indicators.successRate | number:'1.0-0'}}%</td>
                    <td>
                        <div *ngIf="indicators.successRateProgress  >= 0; else lowScore">
                            <i class="fa-solid fa-arrow-trend-up"></i>
                        </div>
                        <ng-template #lowScore>
                            <i class="fa-solid fa-arrow-trend-down"></i>
                          </ng-template>
                    </td>
                    <td class="indicatorRateProgression">
                        {{toAbsolute(indicators.successRateProgress) | number: '1.0-2'}}%
                    </td>
                </tr>
            </table>

        </div>
        <div class="simpleIndicator">
            Engagement<br><br>
            <table style="width: 100%;">
                <tr>
                    <td class="indicatorRate">{{indicators.engagementRate | number:'1.0-0'}}%</td>
                    <td>
                        <div *ngIf="indicators.engagementRateProgress  >= 0; else lowScore">
                            <i class="fa-solid fa-arrow-trend-up"></i>
                        </div>
                        <ng-template #lowScore>
                            <i class="fa-solid fa-arrow-trend-down"></i>
                          </ng-template>
                    </td>
                    <td class="indicatorRateProgression">
                       {{toAbsolute(indicators.engagementRateProgress) | number: '1.0-2'}}%
                    </td>
                  
                </tr>
            </table>
        </div>
    </div>
    <div class="simpleIndicatorsContainer">
        <div class="simpleIndicator">
            Taux de satisfaction<br>
            <div class="smallGreyText">Pour tous les projets</div>
            <div class="parent-smiley-container">
                <div class="gauge-container">
                    <ngx-gauge
                        [value]="indicators.successRate"
                        [min]="0"
                        [max]="100"
                        [type]="'semi'"
                        [thick]="15"
                        [append]="'%'"
                        [animate]="true"
                        [label]="''">
                    </ngx-gauge>
                </div>
                <div class="smiley-container">
                    <i class="far fa-face-grin fa-2xl"></i>
                </div>
            </div>
            <div class="satisfactionRateContainer">
                {{indicators.successRate | number:'1.0-0'}}%
            </div>
        </div>
        <div class="newTasksIndicator">
            <br>
           <div class="bigTextBold whiteColor textCenter">
            <p>{{ indicators.newTasks }} nouvelle{{ indicators.newTasks > 1 ? 's' : '' }} tâche{{ indicators.newTasks > 1 ? 's' : '' }}</p>
           </div>
           <br>
           <br>
           <table class="whiteColor contentCenter large-text textCenter marginAuto">
                <tr>
                    <td>A assigner aux pratiquants</td>
                    <td><i class="fa-solid fa-file-pen fa-2xl"></i></td>
                </tr>
           </table>
        </div>
    </div>
    <div class="simpleIndicator">
        <div class="normalText">Indicateur de cricité des tâches analysées<br></div>
        <br>
        <div class="bigTextBold">Score Criticité globale : {{indicators.analyzedTasksCriticityScore | number:'1.0-1'}}</div><br>
        <div class="score-bar-container">
            <div 
                class="score-bar" 
                [style.width.%]="(indicators.analyzedTasksCriticityScore / 5) * 100">
            </div>
        </div>
    </div>
    

</div>