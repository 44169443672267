import { Injectable } from '@angular/core';
import { WorkTaskFile } from '../../models/work-task-file.model';
import { HttpClient, HttpEventType, HttpEvent } from '@angular/common/http';
import { environment } from '@env/environment';
import { from, Observable, BehaviorSubject  } from 'rxjs';
import { TimeComment } from '../../models/time-comment.model';
import { concatMap, last, switchMap, tap, finalize } from 'rxjs/operators';
import { FileUpload } from '../../models/file-upload.model';
@Injectable({
  providedIn: 'root'
})
export class FileService {

  private apiUrl = environment.apiUrl  + 'file';
  private filesInProgressSubject = new BehaviorSubject<FileUpload[]>([]);
  public filesInProgress$ = this.filesInProgressSubject.asObservable();
  constructor(private http: HttpClient) {}

  private updateFileProgress(fileName: string, progress: number) {
    const currentFiles = this.filesInProgressSubject.getValue();
    const fileIndex = currentFiles.findIndex(f => f.fileName === fileName);

    if (fileIndex === -1) {
      this.filesInProgressSubject.next([...currentFiles, { fileName, progress }]);
    } else {
      const updatedFiles = [...currentFiles];
      updatedFiles[fileIndex] = { fileName, progress };
      this.filesInProgressSubject.next(updatedFiles);
    }
  }

  private removeFileFromProgress(fileName: string) {
    const currentFiles = this.filesInProgressSubject.getValue();
    const updatedFiles = currentFiles.filter(f => !(f.fileName === fileName));
    this.filesInProgressSubject.next(updatedFiles);
  }

  uploadWorkTaskFileFromUrl(url: string, workTaskFileId: string): Observable<HttpEvent<WorkTaskFile>> {
    return this.http.post<WorkTaskFile>(`${this.apiUrl}/${workTaskFileId}/uploadFromUrl`, { "urlFile": url }, {
      headers: { 'Content-Type': 'application/json' },
      reportProgress: true,
      observe: 'events'
    });
  }

  uploadWorkTaskFile(file: File, workTaskFileId: string): Observable<any> {
    const chunkSize = 10 * 1024 * 1024; // 10 MB
    const totalChunks = Math.ceil(file.size / chunkSize);
    const fileName = file.name;

    let totalLoaded = 0; // Total des octets chargés
    let totalProgress = 0; // Progression globale

    const uploadChunk = (chunk: Blob, chunkIndex: number): Observable<HttpEvent<any>> => {
        const uploadData = new FormData();
        uploadData.append('chunk', chunk, fileName);
        uploadData.append('fileName', fileName);
        uploadData.append('chunkIndex', chunkIndex.toString());
        uploadData.append('totalChunks', totalChunks.toString());
        return this.http.post<any>(`${this.apiUrl}/${workTaskFileId}/upload-chunk`, uploadData, {
            reportProgress: true,
            observe: 'events',
        });
    };

    // Créer un tableau avec les chunks à uploader
    const chunkTasks = Array.from({ length: totalChunks }, (_, index) => {
        const start = index * chunkSize;
        const end = Math.min(start + chunkSize, file.size);
        const chunk = file.slice(start, end);
        return { chunk, index };
    });

    return from(chunkTasks).pipe(
        concatMap(({ chunk, index }) => {
            return uploadChunk(chunk, index).pipe(
                tap(event => {
                    if (event.type === HttpEventType.UploadProgress && event.total) {
                        // Cumul des données chargées
                        totalLoaded += event.loaded;
                        
                        // Calcul de la progression globale en fonction des chunks déjà envoyés
                        totalProgress = Math.round((totalLoaded / file.size) * 100);
                        this.updateFileProgress(fileName, totalProgress); // Mise à jour de la progression globale
                    }
                })
            );
        }),
        finalize(() => this.removeFileFromProgress(fileName)) // Supprimez l'élément de la progression une fois terminé
    );
}
  
  setComments(workTaskFileId: string, comments : TimeComment[]): Observable<WorkTaskFile> {
    return this.http.post<WorkTaskFile>(`${this.apiUrl}/${workTaskFileId}/comments/`, JSON.stringify(comments), {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  getComments(workTaskFileId: string): Observable<TimeComment[]> {
    return this.http.get<TimeComment[]>(`${this.apiUrl}/${workTaskFileId}/comments`);
  }

  getFileUrl(workTaskFileId: string): Observable<string> {
    return this.http.get<string>(`${this.apiUrl}/${workTaskFileId}/url`);
  }
}