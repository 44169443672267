import { Component, OnInit  } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
@Component({
  selector: 'app-tenant-selection',
  templateUrl: './tenant-selection.component.html',
  styleUrl: './tenant-selection.component.css'
})
export class TenantSelectionComponent implements OnInit {
  tenants = [
    { name: 'Tenant 1' },
    { name: 'Tenant 2' },
    { name: 'Tenant 3' }
  ];

  constructor(private keycloakService: KeycloakService) {}

  ngOnInit(): void {}

  selectTenant(tenant: any): void {
    // Logic to select tenant and set context in Keycloak
  }
}
