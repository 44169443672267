// cogeo.service.ts
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { CogeoMessageAnswer } from '../../models/cogeo-message.answer.model';
import { TimeComment } from '../../models/time-comment.model';
import { Router } from '@angular/router';
import { GenericPopinService } from '../generic-popin/generic-popin.service';
import { WaitingPopinService } from '../waiting-popin/waiting-popin.service';
import { ProfessionService } from '../training/profession.service';
import { WorkTaskService } from '../training/work-task.service';
import { CogeoStatusEnum } from 'src/app/enums/cogeo-status.enum';
import { CogeoGenerationStatus } from 'src/app/models/cogeo-generation-status.model';

@Injectable({
  providedIn: 'root'
})
export class CogeoService {
  private apiUrl = environment.apiUrl + 'cogeo';
  private cogeoWebSocketUrl = environment.cogeoWebSocketUrl;
  private socket!: WebSocket;
  private messagesSubject = new Subject<CogeoMessageAnswer>();
  public messages$ = this.messagesSubject.asObservable();

  constructor(
    private http: HttpClient, 
    private router: Router, 
    private finalPopin: GenericPopinService, 
    private professionService: ProfessionService, 
    private workTaskService: WorkTaskService, 
    private waitingPopin: WaitingPopinService) {}

 
  public initializeAnalysis(professionId: string, workTaskId: string, workTaskFileId: string, videoComments: TimeComment[]) {
    let endChatMessage : string = "Analysis complete";
    let taskId: number = -1;

    this.workTaskService.getTaskDetail(workTaskId).subscribe(task => {
      const tryConnect = () => {
        this.socket = new WebSocket(this.cogeoWebSocketUrl + "/start_analysis");
        this.socket.onopen = () => {
           let cogeoComments = {comments: videoComments.map(v => v.modifiedComment || v.comment).join(' '), approach : task.cogeoApproachId, level : task.cogeoLevelId};
           this.socket.send(JSON.stringify(cogeoComments));
        };
  
        this.socket.onmessage = (event) => {
           let messageData = JSON.parse(event.data);
           this.messagesSubject.next(messageData);
 
           if (messageData.task_id != undefined){
             taskId = messageData.task_id;
           }
 
           if(messageData.message == endChatMessage){
              this.showFinalPopin(professionId, workTaskId, workTaskFileId, taskId);
           }
        };
   
        this.socket.onerror = (error) => {
           console.error('Erreur WebSocket détectée, voir les détails dans la console Réseau.');
           this.socket.close();
        };
     };
     tryConnect();
    });
 }
 
  public showFinalPopin(professionId: string, workTaskId: string, workTaskFileId: string, cogeoTaskId:number) {
    this.finalPopin.open(
      'L\'échange avec Cogeo est maintenant terminé, nous pouvons générer le contenu',
      () => this.generateFirstCourse(professionId, workTaskId, cogeoTaskId, workTaskFileId)
    );

  }

  public sendMessage(message: string): void {
    const payload = { answer : message};
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(payload));
    }
  }

  public checkStatus(workTaskId: string, cogeoTaskId: number, workTaskFileId: string, cogeoJobId: string): Observable<boolean> {
    const payload = { workTaskId: workTaskId, workTaskFileId: workTaskFileId, cogeoTaskId: cogeoTaskId, cogeoJobId: cogeoJobId };
    const params = new HttpParams({ fromObject: payload });
  
    return this.http.get<CogeoGenerationStatus>(`${this.apiUrl}/checkGenerationStatus`, { params }).pipe(
      map((response: CogeoGenerationStatus) => {
        if (response.status == CogeoStatusEnum.Completed) {
          return true;
        } else if (response.status == CogeoStatusEnum.Failed) {
          alert("Erreur lors de la génération, veuillez vérifiez les logs");
          return false;
        }
        return false;
      }),
      catchError((error) => {
        alert("Erreur lors de la génération, veuillez vérifiez les logs");
        return [false]; // Retourne `false` en cas d'erreur
      })
    );
  }

  public generateFirstCourse(professionId: string, workTaskId: string, cogeoTaskId: number, workTaskFileId: string) {
    this.waitingPopin.showPopin("Génération en cours");
    const payload = { workTaskId: workTaskId, workTaskFileId: workTaskFileId, cogeoTaskId: cogeoTaskId };
  
    this.http.post(`${this.apiUrl}/generateFirstCourse`, payload).subscribe(
      (response: any) => {
        const jobId = response.job_id;
  
        setTimeout(() => {
          this.checkStatus(workTaskId, cogeoTaskId, workTaskFileId, jobId).subscribe((check1) => {
            if (check1) {
              this.finalizeGeneration(professionId, workTaskId);
            }
  
            const interval = setInterval(() => {
              this.checkStatus(workTaskId, cogeoTaskId, workTaskFileId, jobId).subscribe((check2) => {
                if (check2) {
                  clearInterval(interval);
                  this.finalizeGeneration(professionId, workTaskId);
                }
              });
            }, 60000);
          });
        }, 120000);
      },
      (error) => {
        console.error('Erreur lors de la génération du cours', error);
      }
    );
  }
  
  private finalizeGeneration(professionId: string, workTaskId: string) {
    this.waitingPopin.hidePopin();
    this.professionService.getDefaultProfessionId().subscribe(() => {
      this.router.navigate(['training/learning/profession/', professionId, workTaskId]);
    });
  }

  synthesizeText(message: string): Observable<Blob> {
    return this.http.get<Blob>(`${this.apiUrl}/synthesize?text=${encodeURIComponent(message)}`, { responseType: 'blob' as 'json' });
  }
}
