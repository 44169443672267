import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appSafeHtml]'
})
export class SafeHtmlDirective implements OnChanges {
  @Input() appSafeHtml: string = '';

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['appSafeHtml']) {
      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', '');
      const content = this.renderer.createElement('div');
      content.innerHTML = this.appSafeHtml;
      this.renderer.appendChild(this.el.nativeElement, content);
    }
  }
}
