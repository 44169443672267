import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CogeoService } from '../../../../services/cogeo/cogeo.service';
import { CogeoDifficultyEnum } from '../../../../enums/cogeo-difficulty.enum';
import { CogeoApproachEnum } from '../../../../enums/cogeo-approach.enum';

@Component({
  selector: 'app-cogeo-parameters',
  templateUrl: './regenerate-course.component.html',
  styleUrl: './regenerate-course.component.css'
})
export class RegenerateCourseComponent {
  difficulties = Object.entries(CogeoDifficultyEnum)
    .filter(([key, value]) => !isNaN(Number(value)))
    .map(([key, value]) => ({ label: key, value: Number(value) }));

  approaches = Object.entries(CogeoApproachEnum)
    .filter(([key, value]) => !isNaN(Number(value)))
    .map(([key, value]) => ({ label: key, value: Number(value) }));

  selectedApproach: number = CogeoApproachEnum["Lean"];
  selectedDifficulty: number = CogeoDifficultyEnum["Confirmé"];
  workTaskFileId: string = '';
  cogeoTaskId: number = -1;

  constructor(
    private cogeoService : CogeoService,
    private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.cogeoTaskId = parseInt(params.get('cogeoTaskId')!);
      this.workTaskFileId = params.get("workTaskFileId")!;
    })
  };

  onGenerate(): void {
    /*if (this.selectedApproach>-1 && this.selectedDifficulty>-1 && this.cogeoTaskId > 0) {
      this.cogeoService.generateCourse(this.cogeoTaskId, this.workTaskFileId, this.selectedApproach, this.selectedDifficulty);

    } else {
      console.log('Veuillez sélectionner une approche et un niveau');
    }*/
  }
}
