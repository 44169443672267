// waiting-popin.component.ts
import { Component } from '@angular/core';
import { WaitingPopinService } from '../../services/waiting-popin/waiting-popin.service';

@Component({
  selector: 'app-waiting-popin',
  templateUrl: './waiting-popin.component.html',
  styleUrls: ['./waiting-popin.component.css']
})
export class WaitingPopinComponent {
  isVisible = false;
  progress = 0;
  message = 'Veuillez patienter...';

  constructor(private popinService: WaitingPopinService) {
    // S'abonner aux observables du service
    this.popinService.isVisible$.subscribe(visible => {
      this.isVisible = visible;
    });

    this.popinService.progress$.subscribe(progress => {
      this.progress = progress;
    });

    this.popinService.message$.subscribe(message => {
      this.message = message;
    });
  }

  // Fermer la popin manuellement
  close() {
    this.popinService.hidePopin();
  }
}
