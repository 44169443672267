import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { VideoCommentComponent } from './pages/training/creation/video-comment/video-comment.component';
import { AuthGuardService } from './services/auth/auth-guard.service'; // Importez le guard
import { LoginComponent } from './pages/login/login.component';
import { ImportVideoComponent } from './pages/training/creation/import-video/import-video.component';
import { PlayVideoComponent } from './pages/training/creation/play-video/play-video/play-video.component';
import { VideoPlayerComponent } from './shared_components/video-player/video-player.component';
import { ProfessionListComponent } from './pages/training/learning/profession-list/profession-list.component';
import { TaskListComponent } from './pages/training/learning/task-list/task-list.component';
import { TaskExplorerComponent } from './pages/training/learning/task-explorer/task-explorer.component';
import { TaskSectionComponent } from './pages/training/learning/task-section/task-section.component';
import { VideoCogeoChatComponent } from './pages/training/creation/video-cogeo-chat/video-cogeo-chat.component';
import { RegenerateCourseComponent } from './pages/training/creation/regenerate-course/regenerate-course.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuardService]  }, // Accessible sans authentification
  { path: 'login', component: LoginComponent },
  { path: 'test', component: VideoPlayerComponent },
  { path: 'training/creation/import-video', component: ImportVideoComponent, canActivate: [AuthGuardService] }, // Protégé par AuthGuard
  { path: 'training/creation/play-video/:professionId/:workTaskId/:workTaskFileId/:filePath', component: PlayVideoComponent, canActivate: [AuthGuardService] }, // Protégé par AuthGuard
  { path: 'training/creation/comment-video/:professionId/:workTaskId/:workTaskFileId/:filePath', component: VideoCommentComponent, canActivate: [AuthGuardService] }, // Protégé par AuthGuard
  { path: 'training/creation/video-cogeo-chat/:professionId/:workTaskId/:workTaskFileId/:filePath', component: VideoCogeoChatComponent, canActivate: [AuthGuardService] }, // Protégé par AuthGuard
  { path: 'training/creation/video-cogeo-chat/:professionId/:workTaskId/:workTaskFileId/:filePath/:cogeoTaskId', component: VideoCogeoChatComponent, canActivate: [AuthGuardService] }, // Protégé par AuthGuard
  { path: 'training/creation/regenerate-course/:workTaskFileId/:cogeoTaskId', component: RegenerateCourseComponent, canActivate: [AuthGuardService] },
  { path: 'training/learning/profession', component: ProfessionListComponent, canActivate: [AuthGuardService] }, // Protégé par AuthGuard
  { path: 'training/learning/profession/:professionId', component: TaskListComponent, canActivate: [AuthGuardService] }, // Protégé par AuthGuard
   // Route pour la partie apprentissage avec layout parent
  {
    path: 'training/learning/profession/:professionId/:workTaskId',
    component: TaskExplorerComponent, // Layout contenant le sommaire
    canActivate: [AuthGuardService], // Protégé par AuthGuard
    children: [
      { path: ':sectionId', component: TaskSectionComponent, canActivate: [AuthGuardService] }, // Protégé par AuthGuard
    ]
  },
  { path: '**', redirectTo: '' } // Redirection vers la page d'accueil pour les routes non définies
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
