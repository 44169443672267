import { Component, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-capture-tab',
  templateUrl: './capture-tab.component.html',
  styleUrls: ['./capture-tab.component.css']
})
export class CaptureTabComponent implements AfterViewInit {
  video: HTMLVideoElement | null = null;
  isHovering: boolean = false;
  isPlaying: boolean = false;
  isPaused: boolean = true;

  ngAfterViewInit() {
    this.video = document.getElementById('video') as HTMLVideoElement;

    if (this.video) {
      this.video.addEventListener('pause', () => {
        this.isPlaying = false;
        this.isPaused = true;
      });

      this.video.addEventListener('play', () => {
        this.isPlaying = true;
        this.isPaused = false;
      });
    }
  }

  playVideo() {
    if (this.video) {
      this.video.play();
    }
  }

  pauseVideo() {
    if (this.video) {
      this.video.pause();
    }
  }

  showControls() {
    this.isHovering = true;
  }

  hideControls() {
    this.isHovering = false;
  }
}
