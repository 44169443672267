import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Observable, from } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private keycloakService: KeycloakService, private http: HttpClient) { }
  init(): Promise<void> {
    return this.keycloakService.init({
      config: environment.keycloak,
      initOptions: {
        checkLoginIframe: false,            // Désactiver le contrôle de l'iframe pour réduire les appels réseau
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html' // URI for silent SSO checks
      },
      enableBearerInterceptor: true,
      bearerPrefix: 'Bearer',
      bearerExcludedUrls: ['/assets'],
      
    }).then(() => {
      // Initialisation réussie
      this.getToken();
      this.http.get(`${environment.apiUrl}` + "user/checkandcreate", {
        responseType: 'text'
      }).subscribe(
          error => window.location.origin + '/assets/silent-check-sso.html' // URI for silent SSO checks
      );

    }).catch(error => {
      console.error('Keycloak initialization failed', error);
      return Promise.reject(error); // Assurez-vous que vous renvoyez la promesse rejetée
    });
  }

  // Méthode pour se connecter
  login(): void {
    this.keycloakService.login();
  }

  // Méthode pour vérifier si l'utilisateur est authentifié
  isAuthenticated(): boolean {
    return this.keycloakService.isLoggedIn();
  }

  // Méthode pour obtenir les informations de l'utilisateur connecté
  getUserProfile(): Promise<Keycloak.KeycloakProfile> {
    return this.keycloakService.loadUserProfile();
  }

  // Méthode pour déconnecter l'utilisateur
  logout(redirectUri: string = window.location.origin): void {
    this.keycloakService.logout(redirectUri);
  }

  // Méthode pour gérer les accès basés sur les rôles
  hasRole(role: string): boolean {
    return this.keycloakService.isUserInRole(role);
  }

  getToken(): Observable<string> {
    return from(this.keycloakService.updateToken(600)).pipe(
      switchMap((refreshed: boolean) => {
        return from(this.keycloakService.getToken());
      }),
      map((token: string) => {
        return token;
      }),
      catchError((error) => {
        console.error('Failed to refresh token', error);
        this.logout();
        return throwError(() => new Error('Failed to refresh token')); 
      })
    );
  }
}