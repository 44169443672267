import { Component, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { CogeoService } from '../../services/cogeo/cogeo.service';
import { CogeoMessageAnswer } from '../../models/cogeo-message.answer.model';
import { CogeoConversation } from '../../models/cogeo-conversation.model';
import { TimeComment } from '../../models/time-comment.model';
import { FileService } from '../../services/file/file.service';
import { cogeoConversationType } from '../../enums/cogeo-conversation-type.enum';
import { DomSanitizer } from '@angular/platform-browser';
import { ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { WorkTaskService } from 'src/app/services/training/work-task.service';
declare var webkitSpeechRecognition: any;
declare var SpeechRecognition: any;
@Component({
  selector: 'app-cogeo-chat',
  templateUrl: './cogeo-chat.component.html',
  styleUrls: ['./cogeo-chat.component.css'],
  providers: [CogeoService]
})
export class CogeoChatComponent implements OnDestroy {
  taskData: any;
  userAnswer: string = '';
  conversation: CogeoConversation[] = [];
  isUserMessageLocked: boolean = true;
  isTyping: boolean = false;
  typingDelay: number = 50;
  lastTranscript: string = '';

  public isSpeechRecognationActivatedByUser: boolean = false;
  public typingInterval: any;
  public isVoiceRecognitionStarted : boolean = false;
  private isVoiceRecognitionAvailable:boolean = 'webkitSpeechRecognition' in window || 'speechRecognition' in window;
  private recognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
  public isRecording: boolean = false;
  private subscriptions: Subscription[] = [];
  private audioInstances: HTMLAudioElement[] = [];
  @Input() workTaskFileId: string = "";
  @Input() workTaskId: string = "";
  @Input() professionId: string = "";
  @Input() cogeoTaskId: number = -1;
  @ViewChild('scrollTarget') private scrollTarget!: ElementRef;
  @ViewChild('userInput') userInput!: ElementRef<HTMLTextAreaElement>;


  constructor(private cogeoService: CogeoService, private workTaskService: WorkTaskService,  private fileService: FileService, private sanitizer: DomSanitizer, private cdr: ChangeDetectorRef) {}
  ngOnInit(): void {
    if (this.workTaskFileId != "" && this.workTaskId != "" && this.professionId != "") {
      this.workTaskService.getTaskDetail(this.workTaskId ).subscribe(task => {
        if (task.cogeoTaskId != undefined)
        {
          this.cogeoTaskId = task.cogeoTaskId;
        }

        if (this.cogeoTaskId>0)
        {
            this.cogeoService.showFinalPopin(this.professionId, this.workTaskId, this.workTaskFileId, this.cogeoTaskId )
        }
        else
        {
          this.initSpeechToTextIfAvailable();

            const messagesSubscription = this.cogeoService.messages$.subscribe((message: CogeoMessageAnswer) => {
              this.subscriptions.push(messagesSubscription);
              this.handleIncomingMessages(message);
            });

            const commentsSubscription = this.fileService.getComments(this.workTaskFileId).subscribe((data) => {
              this.subscriptions.push(commentsSubscription);
              let comments:  TimeComment[] = data;
              this.initializeAnalysis(this.professionId, this.workTaskId, this.workTaskFileId, comments);
            }
            )
          }
      });
    }

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];

    if (this.typingInterval) {
      clearInterval(this.typingInterval);
    }

    if (this.isVoiceRecognitionStarted) {
      this.stopSpeechRecognition();
    }

    this.audioInstances.forEach(audio => {
      audio.pause();
      audio.src = '';
      URL.revokeObjectURL(audio.src);
    });
    this.audioInstances = [];
  }

  private scrollToBottom(): void {
    this.cdr.detectChanges(); // Force la mise à jour de la vue
    if (this.scrollTarget) {
      this.scrollTarget.nativeElement.scrollIntoView({ behavior: 'auto' });
    }
  }

  private addQuestionWithTypingEffect(question: string): void {
    this.conversation.push(new CogeoConversation(false, cogeoConversationType.Question, ""));
    let index = 0;
    this.isTyping = true;
    this.isUserMessageLocked = true;
    this.typingInterval = setInterval(() => {
      if (index < question.length) {
        this.conversation[this.conversation.length - 1].text += question[index];
        index++;
      } else {
        this.isTyping = false;
        clearInterval(this.typingInterval);
        this.isUserMessageLocked = false;
      }
      this.scrollToBottom();
    }, this.typingDelay);
}


  private initializeAnalysis(professionId: string, workTaskId: string, workTaskFileId: string, comments: TimeComment[]): void {
    this.isUserMessageLocked = true;
    this.conversation.push(new CogeoConversation(false, cogeoConversationType.Processing, "..."));
    this.cogeoService.initializeAnalysis(professionId, workTaskId, workTaskFileId,comments);
  }

  private replaceCharactersWithDots(input: string): string {
    return input.replace(/[^ \n]/g, '.');
  }

  private removeAudioInstance(audio: HTMLAudioElement): void {
    const index = this.audioInstances.indexOf(audio);
    if (index > -1) {
      this.audioInstances.splice(index, 1);
    }
  }

  private handleIncomingMessages(message: CogeoMessageAnswer): void {
    if (message.type == "stream"){
      if (!this.conversation[this.conversation.length - 1].isStream){
        this.conversation = this.conversation.filter(message => message.type !== "processing");
        this.conversation.push(new CogeoConversation(true, cogeoConversationType.Question, this.replaceCharactersWithDots(message.content)));
      }
      else{
        this.conversation[this.conversation.length - 1].text +=  this.replaceCharactersWithDots(message.content);
      }
      this.scrollToBottom();
    }
    else
    {
      if ((message.question == undefined || message.question =="") && message.complete_question =="")
        {
          return;
        }
        this.conversation = this.conversation.filter(message => message.type !== "processing");
        this.taskData = message;
        var question = "";
        if (this.taskData.question != undefined)
        {
          question = this.taskData.question;
        }

        if (this.taskData.complete_question != undefined)
        {
          question = this.taskData.complete_question;
        }

        if (question == ""){
          return;
        }


        if (this.conversation.length>=1 && this.conversation[this.conversation.length - 1].isStream){
          var lastMessage =  this.conversation[this.conversation.length - 1];
          lastMessage.text = question;
          lastMessage.type = cogeoConversationType.Question;
          lastMessage.isStream = false;
        }
        else
        {
          this.addQuestionWithTypingEffect(question);
        }
        this.isUserMessageLocked = false;
        this.scrollToBottom();
        this.cogeoService.synthesizeText(question).subscribe({
          next: (audioBlob) => {
            const audioUrl = URL.createObjectURL(audioBlob);
            const audio = new Audio(audioUrl);
            this.audioInstances.push(audio);

            audio.addEventListener('started', () => {
              this.stopSpeechRecognition();
            });
            audio.addEventListener('ended', () => {
              this.startSpeechRecognition();
              URL.revokeObjectURL(audioUrl);
              this.removeAudioInstance(audio);
            });
            audio.play();

          },
          error: (err) => {
            console.error('Error synthesizing text', err);
          }
        });
      this.userAnswer = '';
    }

  }

  private initSpeechToTextIfAvailable()
  {
    const acceptedSendWords = ['envoi', 'envoyer','envoie', 'envoyé', 'envoyée', 'ninja']
     if (this.isVoiceRecognitionAvailable) {

      if ('webkitSpeechRecognition' in window)
      {
        this.recognition = new webkitSpeechRecognition();
      }
      else{
        this.recognition = new SpeechRecognition();
      }

      this.recognition.lang = "fr-FR";
      this.recognition.continuous = true;
      this.recognition.interimResults = true;

      this.recognition.onend = () => {
        if (this.isVoiceRecognitionStarted){
          this.recognition.start();
        }
      };
      this.recognition.onresult = (event: any) => {
        if (this.isVoiceRecognitionStarted)
        {
          let interimTranscript: string = '';
          let isSendingWordFound: boolean = false;
          for (let i = event.resultIndex; i < event.results.length; i++) {
            this.isRecording = true;
            const transcript = event.results[i][0].transcript;

            if (event.results[i].isFinal) {
              this.isRecording = false;

              this.lastTranscript += transcript + ' ';
              var lastWord =  this.lastTranscript.trim().split(' ').pop();
              if (lastWord != null && acceptedSendWords.indexOf(lastWord)>0) {
                this.lastTranscript =  this.lastTranscript.trim().slice(0, -lastWord.length).trim() + ' ';
                isSendingWordFound = true;
              }
            } else {
              interimTranscript += transcript + ' ';
            }
          }

          this.userAnswer =  this.lastTranscript + interimTranscript.trim();
          this.adjustTextareaHeight();
          this.cdr.detectChanges();
          // Appel pour déplacer le curseur à la fin
          this.focusTextareaToEnd();

          if (isSendingWordFound) {
            this.sendMessage();
          }
        }
      };

    }
  }


  public sendMessage(): void {
    this.lastTranscript = "";
    if (this.isTyping || this.userAnswer.trim() === '') {
      return;
    }
    this.stopSpeechRecognition();
    this.isUserMessageLocked = true;
    this.conversation.push(new CogeoConversation(false, cogeoConversationType.Answer, this.userAnswer));
    this.conversation.push(new CogeoConversation(false, cogeoConversationType.Processing, "..."));
    this.scrollToBottom();
    this.cogeoService.sendMessage(this.userAnswer);
    this.userAnswer = '';
    this.resetTextareaHeight();
    this.scrollToBottom();
  }

  public startSpeechRecognitionByUser()
  {
    this.isSpeechRecognationActivatedByUser = true;
    this.startSpeechRecognition();
  }

  public stopSpeechRecognitionByUser()
  {
    this.isSpeechRecognationActivatedByUser = false;
    this.stopSpeechRecognition();
  }

  public startSpeechRecognition()
  {
    if (!this.isSpeechRecognationActivatedByUser)
    {
      return;
    }
    this.recognition.start();
    this.isVoiceRecognitionStarted = true;
  }

  public stopSpeechRecognition()
  {
    this.recognition.stop();
    this.isVoiceRecognitionStarted = false;
  }

  public formatText(text: string) {
    const formattedText = text.replace(/\n/g, '<br>');
    return this.sanitizer.bypassSecurityTrustHtml(formattedText);
  }

  private focusTextareaToEnd(): void {
    if (this.userInput) {
      const textarea = this.userInput.nativeElement;
      const textLength = textarea.value.length;
      textarea.setSelectionRange(textLength, textLength);
      textarea.focus();
    }
  }

  public adjustTextareaHeight(): void {
  if (this.userInput) {
    const textarea = this.userInput.nativeElement;
    textarea.style.height = 'auto';
    const newHeight = Math.min(textarea.scrollHeight, 300);
    textarea.style.height = `${newHeight}px`;
  }
  }

  private resetTextareaHeight(): void {
    if (this.userInput) {
      const textarea = this.userInput.nativeElement;
      textarea.style.height = '100px';
    }
  }

  public onUserAnswerChange(value: string): void{
    this.lastTranscript = value;
    this.adjustTextareaHeight();
  }

  public preventFocus(event: FocusEvent): void {
    if (this.isVoiceRecognitionStarted){
      event.preventDefault();
    }
  }

}
