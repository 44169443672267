import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MainIndicators } from '../../models/mainIndicators.model';
import { UserInformation } from '../../models/user-information';
@Injectable({
  providedIn: 'root'
})
export class HomeService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getIndicators(): Observable<MainIndicators> {
    return this.http.get<MainIndicators>(`${this.apiUrl}dashboard/indicators`);
  }

  getUserInformations() : Observable<UserInformation> {
    return this.http.get<UserInformation>(`${this.apiUrl}user/information`);
  }
}
