<mat-form-field>
    <mat-chip-grid #myChipGridIdValue>
        <mat-chip-row *ngFor="let filling of mySelection; trackBy: trackByFn" (removed)="remove(filling)">
          {{ filling.value }}
          <button matChipRemove [attr.aria-label]="'Remove ' + filling.value">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      
        <input
          #inputField
          [matChipInputFor]="myChipGridIdValue"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)"
          [matAutocomplete]="auto"
          [(ngModel)]="wordInput"
          (ngModelChange)="filterOptions($event)"
          (keydown)="handleKeydown($event)"
          placeholder="Choisissez un élément..." />
      </mat-chip-grid>
    
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions"
                  (click)="selectOption(option)"
                  [disabled]="mySelection.includes(option)">
        {{ option.value }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  