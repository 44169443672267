import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-generic-popin',
  templateUrl: './generic-popin.component.html',
  styleUrl: './generic-popin.component.css'
})
export class GenericPopinComponent {
  @Input() title: string = 'Titre de la popin';
  @Input() buttonContent: string = "OK";
  @Input() img: string = '';
  @Input() isVisible: boolean = false;
  @Output() onClose = new EventEmitter<void>();

  close(): void {
    this.isVisible = false;
    this.onClose.emit();
  }
}
