import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WorkTaskService } from '../../../../services/training/work-task.service';
import { WorkTaskSection } from '../../../../models/work-task-section.model';
@Component({
  selector: 'app-task-summary',
  templateUrl: './task-summary.component.html',
  styleUrl: './task-summary.component.css'
})
export class TaskSummaryComponent {
  sections: WorkTaskSection[] = [];
  workTaskId: string | null = null;
  professionId: string | null = null;
  selectedSectionId: string | null = null;
  constructor(private taskService: WorkTaskService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.workTaskId= this.route.snapshot.paramMap.get('workTaskId');
    this.professionId= this.route.snapshot.paramMap.get('professionId');
    this.taskService.getTaskSummary(this.workTaskId ? this.workTaskId : "").subscribe((data) => {
      this.sections = data;
      this.selectSection(data[0].id);
      this.router.navigate(['/training/learning/profession', this.professionId, this.workTaskId, data[0].id]);
    });
  }

  selectSection(id: string): void {
    this.selectedSectionId = id;
  }

}
