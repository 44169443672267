import { Component, OnInit } from '@angular/core';
import { FileUpload } from '../../models/file-upload.model';
import { FileService } from '../../services/file/file.service';

@Component({
  selector: 'app-upload-progress',
  templateUrl: './upload-progress.component.html',
  styleUrl: './upload-progress.component.css'
})
export class UploadProgressComponent implements OnInit {
    filesInProgress: FileUpload[] = [];
  
    constructor(private fileService: FileService) { }
  
    ngOnInit(): void {
      this.fileService.filesInProgress$.subscribe(files => {
        this.filesInProgress = files;
      });
    }
}
