<div *ngIf="section">
  <div *ngFor="let element of section">
    <ng-container [ngSwitch]="element.type">
      <div *ngSwitchCase="'section-title'">
        <br>
          <h1>{{ element.content }}</h1>
      </div>
       <!-- Bullets list -->
       <div *ngSwitchCase="'bulletlist'">
        <ng-container *ngIf="parseBulletList(element) as bl">
            <ul>
              <li *ngFor="let option of bl.items">
                {{option}}
              </li>
            </ul>
        </ng-container>
      </div>

      <!-- Writing Element -->
      <p *ngSwitchCase="'writing'" [appSafeHtml]="element.content"></p>

      <!-- Checklist Element -->
      <div *ngSwitchCase="'checklist'" class="checklist-container">
        <h2 class="checklist-title">Points clés</h2>
        <ng-container *ngIf="parseCheckList(element) as bl">
          <p *ngFor="let option of bl.items">
            {{option}}
          </p>
        </ng-container>
      </div>

      <!-- QCM  TODO : MANAGE TRUE/BAD SELECTION AND EXPLANATION -->
      <div *ngSwitchCase="'mcq'" class="mcq-container">
        <ng-container *ngIf="parseMcq(element) as qcm">
          <h5 class="question-title">{{ qcm.question }}</h5>
          <form>
            <div class="options-container">
              <label *ngFor="let option of qcm.options; let i = index"
                     class="option-label"
                     [class.selected]="selectedOptions[qcm.id] === option">
                <input
                  type="radio"
                  [name]="qcm.id"
                  [value]="option"
                  (change)="updateSelection(qcm.id, option)"
                />
                <span class="radio-custom"></span>
                <span class="label-text">{{ option }}</span>
              </label>
            </div>
          </form>

          <div *ngIf="selectedOptions[qcm.id]" class="explanation"
               [class.correct]="isMcqAnswerCorrect(qcm)"
               [class.incorrect]="!isMcqAnswerCorrect(qcm)">
            <i class="explanation-icon">
              {{ isMcqAnswerCorrect(qcm) ? '✓' : '✗' }}
            </i>
            <p class="explanation-text">
              {{ isMcqAnswerCorrect(qcm) ? qcm.feedbackCorrect : qcm.feedbackIncorrect }}
            </p>
          </div>
        </ng-container>
      </div>

      <!-- complex-example -->
      <div *ngSwitchCase="'complex-example'">
        <ng-container *ngIf="parseComplexExample(element) as ce">

            <h4 [innerHTML]="ce.contextHeader | markdown | async"></h4>

          <p>
            <span [innerHTML]="ce.contextDescription | markdown | async"></span>
          </p>
          <h5>Problématique :</h5>
          <p>
          <span [innerHTML]="ce.problematic | markdown | async"></span>
          </p>
          <h5>Solution :</h5>
          <p>
            <span [innerHTML]="ce.solution | markdown | async"></span>
          </p>
          <hr>
        </ng-container>
      </div>

      <!-- Slider -->
      <div *ngSwitchCase="'slider'">
        <ng-container *ngIf="parseSlideshow(element) as slideshow">
          <div class="slideshow-container">
            <!-- Slide active -->
            <div *ngFor="let slide of slideshow.slides; let i = index" [ngClass]="{'active-slide': i === slideIndex[element.id] }">
              <h3>{{slide.header}}</h3>
              <div class="active-slide" *ngFor="let slideElement of slide.elements">
                <ng-container [ngSwitch]="slideElement.type">
                  <p *ngSwitchCase="'description'" [appSafeHtml]="slideElement.content"></p>
                  <img *ngSwitchCase="'image'"
                    [width]="parseImage(slideElement.content)?.width"
                    [height]="parseImage(slideElement.content)?.height"
                    [alt]="parseImage(slideElement.content)?.description"
                    [src]="parseImage(slideElement.content)?.url" alt="slide image"
                  />
                </ng-container>
              </div>
            </div>

            <!-- Nav Arrow -->
            <a class="prev" (click)="previousSlide(element.id, slideshow.slides.length)">&#10094;</a>
            <a class="next" (click)="nextSlide(element.id, slideshow.slides.length)">&#10095;</a>
          </div>
        </ng-container>
      </div>

      <!-- Failback -->
      <!--<p *ngSwitchCase="'fallback'" [appSafeHtml]="element.content"></p>-->

      <!-- Short explanation -->
      <p *ngSwitchCase="'short-explanation'" [appSafeHtml]="element.content"></p>

      <!-- Table -->
      <div *ngSwitchCase="'table'">
        <ng-container *ngIf="parseTable(element) as table">
          <div [appSafeHtml]="table.content" class="hide-first-column"></div>
          {{ table.footer }}
        </ng-container>
      </div>

       <!-- simple-example -->
       <div *ngSwitchCase="'simple-example'">
        <ng-container *ngIf="parseSimpleExample(element) as se">
          <br>
          <h4 [appSafeHtml]="se.contextHeader "> </h4>
          <h5>Description :</h5>
          <p [appSafeHtml]="se.contextDescription"></p>
          <h5>Problématique :</h5>
          <p [appSafeHtml]="se.problematic"></p>
          <h5> Solution :</h5>
          <p [appSafeHtml]="se.solution"></p>
          <hr>
        </ng-container>
      </div>
      <!-- expert-feedback -->
      <div *ngSwitchCase="'expert-feedback'">
        <ng-container *ngIf="parseExpertFeedback(element) as ef">
          <br>
          <h4 [appSafeHtml]="ef.header"> </h4>
          <h5>Citation :</h5>
          <p [appSafeHtml]="ef.citation"></p>
          <h5>Détails :</h5>
          <p [appSafeHtml]="ef.details"></p><br/><br/>
          <h5 [appSafeHtml]="ef.keylearningsheader"></h5>
          <p [appSafeHtml]="ef.keylearningspoints"></p>
        </ng-container>
      </div>

      <!-- Long Explanation -->
      <div *ngSwitchCase="'long-explanation'">
        <ng-container *ngIf="parseLongExplanation(element) as le">
          <h4>Contexte : </h4>
          <p [appSafeHtml]="le.detailedBackground"></p>
          <h4>Objectif :</h4>
          <p [appSafeHtml]="le.detailedExplanation"></p>
        </ng-container>
      </div>

      <!-- True / False -->
      <div *ngSwitchCase="'true-false'" class="true-false-container">
        <ng-container *ngIf="parseTrueFalse(element) as truefalse">
          <h3 class="question-title">Vrai/faux :</h3>
          <p class="question-text">{{truefalse.question}}</p>

          <div class="options-container">
            <label class="option-label" [class.selected]="selectedOptions[element.id] === 'true'">
              <input
                type="radio"
                [name]="element.id"
                [value]="true"
                (change)="updateSelection(element.id, 'true')"
              />
              <span class="radio-custom"></span>
              <span class="label-text">Vrai</span>
            </label>

            <label class="option-label" [class.selected]="selectedOptions[element.id] === 'false'">
              <input
                type="radio"
                [name]="element.id"
                [value]="false"
                (change)="updateSelection(element.id, 'false')"
              />
              <span class="radio-custom"></span>
              <span class="label-text">Faux</span>
            </label>
          </div>

          <div *ngIf="selectedOptions[element.id]" class="explanation"
               [class.correct]="isAnswerCorrect(element.id, truefalse)"
               [class.incorrect]="!isAnswerCorrect(element.id, truefalse)">
            <i class="explanation-icon" [innerHTML]="truefalse.explanationIcon"></i>
            <p class="explanation-text">{{truefalse.explanationText}}</p>
          </div>
        </ng-container>
      </div>


    </ng-container>
  </div>
</div>
