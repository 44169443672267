import { Component } from '@angular/core';
import { HomeService } from '../../../services/home/home.service';
import { MainIndicators } from '../../../models/mainIndicators.model';

@Component({
  selector: 'app-analyze-tab',
  templateUrl: './analyze-tab.component.html',
  styleUrl: './analyze-tab.component.css'
})
export class AnalyzeTabComponent {
  public indicators: MainIndicators = new MainIndicators(0,0,0,0,0,0,0,0,0);
  constructor(private homeService: HomeService) { }

  ngOnInit(): void {
     this.homeService.getIndicators().subscribe((data) => {
      this.indicators  = data;
    });

  }

  toAbsolute(value: number): number {
    return Math.abs(value);
  }
}
