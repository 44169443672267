import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class VideoPlayerService {
  private playerStatusSubject = new BehaviorSubject<string>('stopped');
  playerStatus$ = this.playerStatusSubject.asObservable();

  constructor(private http: HttpClient, private authService: AuthService) {}

  setPlayerStatus(value: string) {
    this.playerStatusSubject.next(value);
  }

  addEndListener(videoElement: HTMLVideoElement, callback: () => void): void {
    if (videoElement) {
      videoElement.addEventListener('ended', callback);
    } else {
      console.error('L\'élément passé n\'est pas une balise vidéo HTML valide.');
    }
  }

  addLoadedListener(videoElement: HTMLVideoElement, callback: () => void): void {
    if (videoElement) {
      videoElement.addEventListener('loadeddata', callback);
    } else {
      console.error('L\'élément passé n\'est pas une balise vidéo HTML valide.');
    }
  }

  addPlayingListener(videoElement: HTMLVideoElement, callback: () => void): void {
    if (videoElement) {
      videoElement.addEventListener('playing', callback);
    } else {
      console.error('L\'élément passé n\'est pas une balise vidéo HTML valide.');
    }
  }

  getVideoStream(url: string): Observable<Blob> {
    return from(this.authService.getToken()).pipe(
      switchMap(token => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${token}`, // Ajouter l'en-tête Authorization avec le token
          'Accept': 'video/*' // Spécifier que vous attendez un contenu vidéo
        });

        return this.http.get(url, {
          headers: headers,
          responseType: 'blob' // Assurez-vous de traiter la réponse comme un Blob (stream)
        });
      })
    );
  }
}