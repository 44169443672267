import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { Profession } from '../../models/profession.model';
import { WorkTask } from '../../models/work-task.model';
import { WorkTaskFilter } from '../../models/work-task-filter.model';
import { IdValue } from '../../models/id-value.model';
import { CogeoApproachEnum } from 'src/app/enums/cogeo-approach.enum';
import { CogeoDifficultyEnum } from 'src/app/enums/cogeo-difficulty.enum';
import { WorkTaskInit } from 'src/app/models/work-task-init.model';
@Injectable({
  providedIn: 'root',
})
export class ProfessionService {
  private apiUrl = environment.apiUrl  + 'profession';
  constructor(private http: HttpClient) {}

  getProfessions(): Observable<Profession[]> {
    return this.http.get<Profession[]>(this.apiUrl);
  }

  getProfession(professionId: string): Observable<Profession> {
    return this.http.get<Profession>(`${this.apiUrl}/${professionId}`);
  }

  getTasks(professionId: string, filter: WorkTaskFilter): Observable<WorkTask[]> {
    return this.http.post<WorkTask[]>(`${this.apiUrl}/${professionId}/tasks`,filter);
  }

  getTags(professionId: string) {
    return this.http.get<string[]>(`${this.apiUrl}/${professionId}/tags`);
  }

  getDefaultProfessionId(){
    return this.http.get<string>(`${this.apiUrl}/defaultId`);
  }

  getExperts(professionId: string) {
    return this.http.get<IdValue[]>(`${this.apiUrl}/${professionId}/experts`);
  }

  initializeWorkTask(professionId: string, params: WorkTaskInit): Observable<WorkTask> {
    return this.http.post<WorkTask>(`${this.apiUrl}/${professionId}/task`, params);
  }

}
