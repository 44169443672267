import { Component } from '@angular/core';

@Component({
  selector: 'app-task-explorer',
  templateUrl: './task-explorer.component.html',
  styleUrl: './task-explorer.component.css'
})
export class TaskExplorerComponent {

}
