import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
@Component({
  selector: 'app-play-video',
  templateUrl: './play-video.component.html',
  styleUrl: './play-video.component.css'
})
export class PlayVideoComponent {
  videoFile: string = "";
  filePath : string = "";
  workTaskFileId : string = "";
  workTaskId: string = "";
  professionId: string = "";
  constructor(private router: Router, private route: ActivatedRoute) { }


  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {

        const filePath = params.get('filePath');
        const workTaskFileId = params.get('workTaskFileId');
        const professionId = params.get('professionId');
        const workTaskId = params.get('workTaskId');
        this.workTaskFileId =  workTaskFileId !== null ? workTaskFileId :"";
        this.workTaskId =  workTaskId !== null ? workTaskId :"";
        this.professionId =  professionId !== null ? professionId :"";
        this.filePath = filePath !== null ? filePath :"";
        this.videoFile = decodeURIComponent(this.filePath);
    });
  }

  onCancel()
  {
    this.router.navigate(['/']);
  }

  onNext()
  {
    this.router.navigate(['training/creation/comment-video/', this.professionId, this.workTaskId, this.workTaskFileId,this.filePath]);
  }

}
