import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { IdValue } from '../../models/id-value.model';

@Component({
  selector: 'app-autocomplete-bubbles-couple-values',
  templateUrl: './autocomplete-bubbles-couple-values.component.html',
  styleUrls: ['./autocomplete-bubbles-couple-values.component.css']
})
export class AutocompleteBubblesCoupleValuesComponent implements OnChanges {
  @Input() fillings: IdValue[] = [];
  @Input() mySelection: IdValue[] = [];
  @Output() mySelectionChange: EventEmitter<IdValue[]> = new EventEmitter<IdValue[]>();

  separatorKeysCodes: number[] = [ENTER, COMMA];
  wordInput: string = '';
  filteredOptions: IdValue[] = [];

  ngOnChanges() {
    this.filterOptions(this.wordInput);
  }

  filterOptions(value: string) {
    if (!value) {
      this.filteredOptions = this.fillings;
      return;
    }
    const filterValue = value.toLowerCase();
    this.filteredOptions = value 
      ? this.fillings.filter(option => option.value.toLowerCase().includes(filterValue))
      : this.fillings;
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim(); 

    const selectedOption = this.fillings.find(option => option.value.toLowerCase() === value.toLowerCase());

    if (selectedOption && !this.mySelection.some(item => item.id === selectedOption.id)) {
      this.mySelection.push(selectedOption);
      this.mySelectionChange.emit(this.mySelection);
    }

    event.input.value = '';
    this.wordInput = '';
    this.filteredOptions = this.fillings; // Réinitialise les options filtrées
  }

  remove(filling: IdValue): void {
    const index = this.mySelection.indexOf(filling);
    if (index >= 0) {
      this.mySelection.splice(index, 1);
      this.mySelectionChange.emit(this.mySelection);
    }
  }

  selectOption(option: IdValue): void {
    if (!this.mySelection.some(item => item.id === option.id)) {
      this.mySelection.push(option);
      this.clearInput();
      this.mySelectionChange.emit(this.mySelection);
      console.log(this.mySelection);
    }
  }

  clearInput(): void {
    this.wordInput = '';
  }

  handleKeydown(event: KeyboardEvent): void {
    if ((event.key === 'Enter' || event.key === 'Tab') && this.filteredOptions.length > 0) {
      event.preventDefault();
      this.selectOption(this.filteredOptions[0]);
    }
  }

  trackByFn(index: number, item: IdValue): string {
    console.log( this.filteredOptions);
    return item.id;
  }
}
