<div class="main-container">
  <div class="container">
      <label for="approach">Choisir une approche :</label>
      <select id="approach" [(ngModel)]="selectedApproach" >
          <option *ngFor="let approach of approaches" [ngValue]="approach.value">
              {{ approach.label }}
          </option>
      </select>
    
      <label for="difficulty">Choisir un niveau :</label>
      <select id="difficulty" [(ngModel)]="selectedDifficulty">
          <option *ngFor="let difficulty of difficulties" [ngValue]="difficulty.value">
              {{ difficulty.label }}
          </option>
      </select>

      <div class="button-container">
          <button class="next-button" (click)="onGenerate()">Valider</button>
      </div>
  </div>
</div>
<app-waiting-popin></app-waiting-popin>