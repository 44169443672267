import { Component  } from '@angular/core';
import { Router } from '@angular/router'; 
import { Profession} from '../../../../models/profession.model';
import { ProfessionService } from '../../../../services/training/profession.service';

@Component({
  selector: 'app-profession-list',
  templateUrl: './profession-list.component.html',
  styleUrl: './profession-list.component.css'
})
export class ProfessionListComponent {
  professions: Profession[] = [];

  constructor(private professionService: ProfessionService, private router: Router) {}

  ngOnInit(): void {
    this.professionService.getProfessions().subscribe((data) => {
      this.professions = data;
    });
  }

  goToProfessionTasksList(profession: Profession): void {
    this.router.navigate(['/training/learning/profession', profession.id]);
  }
}
