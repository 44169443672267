import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WorkTaskService } from '../../../../services/training/work-task.service';
import { MultiChoiceQuestionnaire } from '../../../../models/multi-choice-questionnaire.model';
import { SlideshowContent } from '../../../../models/slide-show-content.model';
import { WorkTaskSectionElement } from '../../../../models/work-task-section-element.model';
import { BulletList } from 'src/app/models/bullet-list.model';
import { CheckList } from 'src/app/models/check-list.model';
import { ComplexExample } from 'src/app/models/complex-example.model';
import { Image } from 'src/app/models/image.model';
import { Table } from 'src/app/models/table.model';
import { SimpleExample } from 'src/app/models/simple-example.model';
import { LongExplanation } from 'src/app/models/long-explanation.model';
import { TrueFalseQuestion } from 'src/app/models/true-false-question.model';
import { ExpertFeedback } from 'src/app/models/expert-feedback.model';

@Component({
  selector: 'app-section',
  templateUrl: './task-section.component.html',
  styleUrls: ['./task-section.component.css',],
  encapsulation: ViewEncapsulation.None
})
export class TaskSectionComponent implements OnInit {
  selectedOptions: { [key: string]: string | null } = {};
  slideIndex: { [key: string]: number } = {};
  section: WorkTaskSectionElement[] | null = null;
  workTaskId: string | null = null;
  sectionId: string | null = null;

  constructor(private route: ActivatedRoute, private taskService: WorkTaskService) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.sectionId = params.get('sectionId');
      this.route.parent?.paramMap.subscribe(parentParams => {
        this.workTaskId = parentParams.get('workTaskId');
        if (this.workTaskId && this.sectionId) {
          this.taskService.getSectionContent(this.workTaskId, this.sectionId).subscribe((data) => {
            this.section = data;
          });
        }
      });
    });
  }

  parseBulletList(element: WorkTaskSectionElement): BulletList | null {
    try {
      return JSON.parse(element.content) as BulletList;

    } catch (error) {
      console.error('Erreur lors du parsing de la liste à bulle', error);
      return null;
    }
  }

  parseCheckList(element: WorkTaskSectionElement): CheckList | null {
    try {
      return JSON.parse(element.content) as CheckList;

    } catch (error) {
      console.error('Erreur lors du parsing de la checklist', error);
      return null;
    }
  }

  parseComplexExample(element: WorkTaskSectionElement): ComplexExample | null {
    try {
      return JSON.parse(element.content) as ComplexExample;

    } catch (error) {
      console.error('Erreur lors du parsing de l\'exemple complexe', error);
      return null;
    }
  }

  parseSimpleExample(element: WorkTaskSectionElement): SimpleExample | null {
    try {
      return JSON.parse(element.content) as SimpleExample;

    } catch (error) {
      console.error('Erreur lors du parsing de l\'exemple complexe', error);
      return null;
    }
  }

  parseExpertFeedback(element: WorkTaskSectionElement): ExpertFeedback | null {
    try {
      return JSON.parse(element.content) as ExpertFeedback;
    } catch (error) {
      console.error('Erreur lors du parsing de la table', error);
      return null;
    }
  }

  parseTable(element: WorkTaskSectionElement): Table | null {
    try {
      return JSON.parse(element.content) as Table;
    } catch (error) {
      console.error('Erreur lors du parsing de la table', error);
      return null;
    }
  }

  parseTrueFalse(element: WorkTaskSectionElement): TrueFalseQuestion | null {
    try {
      return JSON.parse(element.content) as TrueFalseQuestion;
    } catch (error) {
      console.error('Erreur lors du parsing de la table', error);
      return null;
    }
  }

  parseLongExplanation(element: WorkTaskSectionElement): LongExplanation | null {
    try {
      const parsed = JSON.parse(element.content) as LongExplanation;

      if (parsed.detailedExplanation && parsed.detailedBackground) {
        parsed.detailedExplanation = parsed.detailedExplanation.replace(/(\.)\s*([A-Z])/g, '$1<br>$2');
        parsed.detailedBackground = parsed.detailedBackground.replace(/(\.)\s*([A-Z])/g, '$1<br>$2');

      }
      return parsed;
    } catch (error) {
      console.error('Erreur lors du parsing de la table', error);
      return null;
    }
  }

  parseMcq(element: WorkTaskSectionElement): MultiChoiceQuestionnaire | null {
    try {
      var mcq = JSON.parse(element.content) as MultiChoiceQuestionnaire;
      mcq.id = element.id;
      return mcq;
    } catch (error) {
      console.error('Erreur lors du parsing du QCM', error);
      return null;
    }
  }

  parseSlideshow(element: WorkTaskSectionElement): SlideshowContent | null {
    try {
      if (!this.slideIndex[element.id]){
        this.slideIndex[element.id] = 0;
      }
      return JSON.parse(element.content) as SlideshowContent;
    } catch (error) {
      console.error('Erreur lors du parsing du diaporama', error);
      return null;
    }
  }


  parseImage(imageSerialized: string) : Image | null {
    try {
      var objImage =  JSON.parse(imageSerialized) as Image;
      objImage.url = `https://placehold.co/${objImage.width}x${objImage.height}`;
      return objImage;
    } catch (error) {
      console.error('Erreur lors du parsing du diaporama', error);
      return null;
    }
  }

  nextSlide(slideshowId: string, slideCount: number): void {
    if (!this.slideIndex[slideshowId]) {
      this.slideIndex[slideshowId] = 0;
    }
    this.slideIndex[slideshowId] = (this.slideIndex[slideshowId] + 1) % slideCount;
  }

  previousSlide(slideshowId: string, slideCount: number): void {
    if (!this.slideIndex[slideshowId]) {
      this.slideIndex[slideshowId] = 0;
    }
    this.slideIndex[slideshowId] = (this.slideIndex[slideshowId] - 1 + slideCount) % slideCount;
  }

  updateSelection(questionId: string, selectedOption: string): void {
    this.selectedOptions[questionId] = selectedOption;
  }

  isAnswerCorrect(elementId: string, trueFalseQuestion: TrueFalseQuestion): boolean {
    return this.selectedOptions[elementId] === trueFalseQuestion.answer.toString();
  }

  isMcqAnswerCorrect(mcq: MultiChoiceQuestionnaire): boolean {
    return this.selectedOptions[mcq.id] === mcq.options[mcq.correctAnswerIndex];
  }


}
