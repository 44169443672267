<div class="container">
    <div class="veryBigTextBold">Vos données...</div>
    <br>
    <div class="video-container" 
        (mouseenter)="showControls()" 
        (mouseleave)="hideControls()">
        <video id="video" class="rounded-video" autoplay>
            <source src="https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4">
            Votre navigateur ne prend pas en charge la vidéo.
        </video>
        <button *ngIf="isHovering && isPaused" class="video-control" (click)="playVideo()">&#9658;</button>
        <button *ngIf="isHovering && isPlaying" class="video-control" (click)="pauseVideo()">&#10074;&#10074;</button>
    </div>
    <div class="mainContent">
        <table  class="mainTable">
            <tr>
                <td>
                    <i class="fa-solid fa-video fa-xl"></i>
                </td>
                <td style="width: 20px;"></td>
                <td>
                    <div class="bigTextBold">Captation vidéo</div>
                    <div class="normalText">Filmer une tâche de travail</div>
                    <br>
                </td>
            </tr>
            <tr>
                <td>
                    <i class="fa-solid fa-message fa-xl"></i>
                </td>
                <td style="width: 20px;"></td>
                <td>
                    <div class="bigTextBold">Commentaires</div>
                    <div class="normalText">Commenter vos vidéos pour<br>détailler vos actions</div>
                    <br>
                </td>
            </tr>
            <tr>
                <td>
                    <i class="fa-solid fa-comments fa-xl"></i>
                </td>
                <td style="width: 20px;"></td>
                <td>
                    <div class="bigTextBold">Discussion avec Cogeo</div>
                    <div class="normalText">Echanger avec notre IA pour<br>approfondir ses connaissances</div>
                </td>
            </tr>
        </table>
    </div>
</div>