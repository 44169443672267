<div class="container">
    <div class="bigTextBold">...approfondies...</div>
    <br>
    <button class="styled-button" (click)="goToContentCreationPage()">Démarrer</button>
    <div class="description">
        Obtenez des contenus de <b>qualité</b> et <b>personnalisés</b> en 1 heure
        <br><br>
        <table>
            <tr>
                <td>
                    <i class="fa-solid fa-play fa-xl"></i>
                </td>
                <td style="width: 20px;"></td>
                <td>
                    <b>Vidéo</b><br>
                    Des vidéos immersives issues de vos terrains
                    <br><br>
                </td>
            </tr>
            <tr>
                <td>
                    <i class="fa-solid fa-file-lines fa-xl"></i>
                </td>
                <td style="width: 20px;"></td>
                <td>
                    <b>Documentation</b><br>
                    Importez et présentez vos documents
                    <br><br>
                </td>
            </tr>
            <tr>
                <td>
                    <i class="fa-solid fa-arrow-down-up-across-line fa-xl"></i>
                </td>
                <td style="width: 20px;"></td>
                <td>
                    <b>Ré-édition</b><br>
                    L'éditeur en ligne vous permet d'enrichir et d'améliorer les contenus pour encore plus de personnalisation
                </td>
            </tr>
        </table>
    </div>
    <img src="/assets/pictures/cogeo_avatar.png" alt="Image" class="bottom-left-image">
</div>
