export class TimeComment {
    constructor(
        public startTime: number,
        public endTime : number,
        public comment: string,
        public modifiedComment : string,
        public isHighlighted : boolean,
        public isEditable : boolean,
        public isGotoComment: boolean
    ) {}
  }
