import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenericPopinService {

  constructor() { }

  private isVisible$ = new BehaviorSubject<boolean>(false);
  private content$ = new BehaviorSubject<string | null>(null);
  private actionToCallOnClose: (() => void) | null = null;
  getModalState() {
    return this.isVisible$.asObservable();
  }

  getContent() {
    return this.content$.asObservable();
  }

  open(content: string, action?: () => void): void {
    this.content$.next(content);
    this.isVisible$.next(true);
    
    if (action) {
      this.actionToCallOnClose = action;
    }
  }

  close(): void {
    this.isVisible$.next(false);
    this.content$.next(null);
    if (this.actionToCallOnClose)
    {
      this.actionToCallOnClose();
    }
  }
}
