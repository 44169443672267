<div class="chat-container">
  <div class="message-container">
    <div
      *ngFor="let message of conversation; let isLast = last"
      class="message"
      [ngClass]="{
        question: message.type === 'question',
        answer: message.type === 'answer',
        processing: message.type === 'processing'
      }"
    >
      <div class="message-content">
        <!-- Include the question logo -->
        <img
          *ngIf="message.type === 'question'"
          src="assets/pictures/Cogeo_without_bg.png"
          alt="Question Logo"
          class="logo"
        />
        <img
          *ngIf="message.type === 'processing'"
          src="assets/pictures/Cogeo_without_bg.png"
          alt="Answer Logo"
          class="logo"
        />
        <p [innerHTML]="formatText(message.text)">

        </p>
        <!-- Cible de scroll sur le dernier message -->
        <div *ngIf="isLast" #scrollTarget></div>
      </div>
    </div>
  </div>
  <div class="divider"></div>

  <div class="input-container">
    <textarea
    #userInput
    class="input-area"
    (focus)="preventFocus($event)"
    [(ngModel)]="userAnswer"
    (keydown.enter)="sendMessage()"
    (ngModelChange)="onUserAnswerChange($event)"
    [placeholder]="isUserMessageLocked ? 'En attente de la question...' : 'Votre message...'"
    [disabled]="isUserMessageLocked"
    [ngClass]="{ 'placeholder-disabled': isUserMessageLocked }"
  ></textarea>

    <button
      class="send-btn"
      (click)="sendMessage()"
      [disabled]="isUserMessageLocked">
      <i class="fa-solid fa-paper-plane"></i>
    </button>

    <button class="mic-btn" [disabled]="isUserMessageLocked" (click)="stopSpeechRecognitionByUser()" [hidden]="!isVoiceRecognitionStarted || !isRecording"><img src="/assets/pictures/recording-icon.gif" width="30px"/></button>
    <button class="mic-btn" [disabled]="isUserMessageLocked" (click)="stopSpeechRecognitionByUser()" [hidden]="!isVoiceRecognitionStarted || isRecording"><i class="fas fa-ellipsis"></i></button>
    <button class="mic-btn" [disabled]="isUserMessageLocked" (click)="startSpeechRecognitionByUser()" [hidden]="isVoiceRecognitionStarted"><i class="fas fa-microphone-slash"></i></button>
  </div>

</div>
