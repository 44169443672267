<nav *ngIf="!isHomePage()" class="navbar navbar-expand-lg custom-navbar">
  <div class="container-fluid navbarContent">
    <div class="navbar-textLeft">
      <span>Démonstrateur</span>
    </div>
    
    <div class="navbar-logo">
      <img src="assets/pictures/cognivance_logo.png" alt="Logo" class="navbar-logo-img" routerLink="/login">
    </div>

    <div class="navbar-textRight">
      <span>Le Beau Geste®</span>
    </div>
    
    <a [hidden]="isAuthenticated" class="nav-link loginLogout" routerLink="/login">Login</a>
    <a [hidden]="!isAuthenticated" class="nav-link loginLogout" href="#" (click)="logout()">Déconnexion</a>
  </div>
</nav>
<div *ngIf="(fileService.filesInProgress$ | async) != undefined && (fileService.filesInProgress$ | async)!.length > 0">
  <app-upload-progress></app-upload-progress>
</div>
<router-outlet></router-outlet>
