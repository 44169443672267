import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-transmit-tab',
  templateUrl: './transmit-tab.component.html',
  styleUrl: './transmit-tab.component.css'
})
export class TransmitTabComponent {
  constructor(private router: Router) { }
  goToContentCreationPage() {
    this.router.navigate([`/training/creation/import-video`]);
  }
}
