import { Component } from '@angular/core';
import { AuthService } from './services/auth/auth.service';
import { Router } from '@angular/router';
import { FileService } from './services/file/file.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'LeBeauGeste';
  isAuthenticated = false;
  userProfile?: Keycloak.KeycloakProfile;
  constructor(private authService: AuthService, private router: Router, public fileService: FileService) { }

  async ngOnInit(): Promise<void> {

      this.isAuthenticated = this.authService.isAuthenticated()
    
  }

  isHomePage(): boolean {
    return this.router.url === '/';
  }

  login() {
    this.authService.login();
  }

  logout() {
      this.authService.logout();
  }
}
