import { Component, OnInit, ElementRef, ViewChild,  Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { VideoPlayerComponent } from '../../../../shared_components/video-player/video-player.component';
import { VideoPlayerService } from '../../../../services/video-player/video-player.service';
import { TimeComment } from '../../../../models/time-comment.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../../../shared_components/confirmation-dialog/confirmation-dialog.component';
import { FormatTimePipe } from 'src/app/pipes/format-time.pipe';
import { Router, ActivatedRoute } from '@angular/router';
import { FileService } from '../../../../services/file/file.service';
declare var webkitSpeechRecognition: any;
declare var SpeechRecognition: any;

@Component({
  selector: 'app-video-comment',
  templateUrl: './video-comment.component.html',
  styleUrl: './video-comment.component.css',
  providers: [FormatTimePipe]
})
export class VideoCommentComponent implements OnInit, AfterViewInit{
  isVoiceRecognitionStart: boolean = false;
  isRecording: boolean = false;
  isShowJson : boolean = false;
  isVoiceRecognitionAvailable:boolean = 'webkitSpeechRecognition' in window || 'speechRecognition' in window;
  isVideoFinished: boolean = false;
  isVideoLoaded: boolean = false;
  currentVideoTime : number = 0;
  videoFile: string = "";
  filePath: string = "";
  workTaskFileId: string = "";
  workTaskId: string = "";
  professionId: string = "";
  @Input() comments : TimeComment[] = [];
  @Input() commentsMod : TimeComment[] = [];
  @Input() textContent: string = "";
  @Input() commentText : string = "";
  @ViewChild('videoPlayerComponent', { static: false }) videoPlayerComponent!: VideoPlayerComponent;
  @ViewChild('scrollableCommentContainer') scrollableCommentContainer!: ElementRef;
  private recognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
  private startSpeechTime: number = 0;
  private preSeekingTime : number = -1;
  private currentTimeInterval: any;


  constructor(
    private cdr: ChangeDetectorRef,
    private fileService: FileService,
    private videoPlayerService: VideoPlayerService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private timePipe: FormatTimePipe) {}

  ngOnInit(): void {
    this.initSpeechToTextIfAvailable();
      this.route.paramMap.subscribe(params => {
      const filePath = params.get('filePath');
      const workTaskFileId = params.get('workTaskFileId');
      const workTaskId = params.get('workTaskId');
      const professionId = params.get('professionId');
      this.workTaskFileId = workTaskFileId !== null ? workTaskFileId :"";
      this.workTaskId = workTaskId !== null ? workTaskId :"";
      this.professionId = professionId !== null ? professionId :"";
      this.filePath = filePath !== null ? filePath :"";
      this.videoFile = decodeURIComponent(filePath?? "");
     
    });
  }

  ngAfterViewInit(): void {
    const videoElement = this.videoPlayerComponent.getHtmlVideoComponent();

    this.videoPlayerService.addEndListener(videoElement, () => {
      this.confirmEndVideo();
    });

    this.videoPlayerService.addLoadedListener(videoElement, () => {
      this.isVideoLoaded = true;
    });
  }

  handleCurrentTimeBeforeChange(time: number) {
    this.preSeekingTime = time
  }

  private confirmEndVideo()
  {
    this.stopSpeechRecognition();
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: 'custom-dialog-container',
      width: '400px',
      height : "250px",
      data: { question: 'La vidéo est terminée, avez-vous terminé vos commentaires ?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result===true) {
        this.isVideoFinished = true;
        this.stopSpeechRecognition();

        this.currentTimeInterval = setInterval(() => this.currentVideoTime = this.getCurrentTime(), 1000);
    }});
  };

  ngOnDestroy(): void {
    if (this.currentTimeInterval) {
      clearInterval(this.currentTimeInterval);
    }
  }

  private initSpeechToTextIfAvailable()
  {
     if (this.isVoiceRecognitionAvailable) {

      if ('webkitSpeechRecognition' in window)
      {
        this.recognition = new webkitSpeechRecognition();
      }
      else{
        this.recognition = new SpeechRecognition();
      }

      this.recognition.lang = "fr-FR";
      this.recognition.continuous = true;
      this.recognition.interimResults = true;
      
      this.recognition.onend = () => {
        if (this.isVoiceRecognitionStart){
          this.recognition.start();
        }
      };
    
      this.recognition.onresult = (event: any) => {
        this.isRecording = true;
        if (this.isVideoFinished)
        {
          this.stopSpeechRecognition();
          this.cdr.detectChanges();
          return;
        }
        var finalTranscript = '';
        if (this.startSpeechTime === -1){
          this.startSpeechTime = this.videoPlayerComponent.getCurrentTime();
        }

        if (event.results[event.results.length-1].isFinal) {

          finalTranscript += event.results[event.results.length-1][0].transcript;
          this.addComment(finalTranscript, this.startSpeechTime, (this.preSeekingTime>=0 ? this.preSeekingTime : this.videoPlayerComponent.getCurrentTime()), false);
          this.preSeekingTime = -1;
          finalTranscript = '';
          this.startSpeechTime = -1;
          this.isRecording = false;
        }

        this.cdr.detectChanges();
      };
      this.recognition.onerror = (event: any) => {
        console.log(event);
      };
    }
  }

  public getCurrentTime()
  {
    return this.videoPlayerComponent.getCurrentTime();
  }

  public startSpeechRecognition()
  {
    this.recognition.start();
    this.isVoiceRecognitionStart = true;
  }

  public stopSpeechRecognition()
  {
    this.recognition.stop();
    this.isVoiceRecognitionStart = false;
  }

  public addComment(commentText: string, startTime: number, endTime: number, goToComment: boolean) : TimeComment {
    const newComment = new TimeComment(startTime, endTime, commentText, "", false, false, goToComment);
    newComment.isHighlighted = false;
    this.comments.push(newComment);
    this.commentsMod.push(newComment);

    this.comments.sort((a, b) => a.startTime - b.startTime);
    this.commentsMod.sort((a, b) => a.startTime - b.startTime);

    this.refreshJsonText();

    if (goToComment){
      this.cdr.detectChanges();
      this.goToComment(newComment);
    }
    return newComment;
  }

  private goToComment(comment: TimeComment)
  {
    const container = this.scrollableCommentContainer.nativeElement;
    const target = container.querySelector('#commentScrollTarget');

    if (target) {
      const targetOffsetTop = (target as HTMLElement).offsetTop;

      container.scrollTo({
        top: targetOffsetTop,
        behavior: 'smooth', // Défilement fluide
      });
      comment.isGotoComment = false;
    }
  }

  private refreshJsonText()
  {
    this.textContent = JSON.stringify(this.comments) + "\n";
    this.startSpeechTime = -1;
  }

  public deleteComment(index: number): void {
    this.comments.splice(index, 1);
    this.commentsMod.splice(index, 1);
    this.cdr.detectChanges(); // Force Angular to detect changes
  }

  public updateComment(comment: TimeComment, newComment: string): void {
    comment.comment = newComment;
    this.highlightComment(comment);
    comment.isEditable = false;

  }
  public enableEditing(comment: TimeComment): void {
    comment.isEditable = true;
  }

  public goToVideoTime(time: number)
  {
    this.videoPlayerComponent.goToVideoTime(time);
  }

  public addNewComment(time: number)
  {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: 'custom-dialog-container',
      width: '400px',
      height : "250px",
      data: { question: `Ajouter un commentaire à ${this.timePipe.transform(time)} de la vidéo?` }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result===true) {
        var comment = this.addComment("", time, time, true)
        comment.isEditable = true;
      }
    });
  }

  public onCancel()
  {
    this.router.navigate(['/']);
  }

  public onNext()
  {
    this.fileService.setComments( this.workTaskFileId, this.commentsMod).subscribe(() => {
      this.router.navigate(['training/creation/video-cogeo-chat/', this.professionId, this.workTaskId, this.workTaskFileId, this.filePath]);
    });
  }

  public highlightComment(timeComment: TimeComment): void {
    timeComment.isHighlighted = true;
    setTimeout(() => {
      timeComment.isHighlighted = false;
    }, 5000);
  }
}
