import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WorkTaskSection } from '../../models/work-task-section.model';
import { environment } from '@env/environment';
import { WorkTaskSectionElement } from '../../models/work-task-section-element.model';
import { WorkTask } from '../../models/work-task.model';
import { WorkTaskFile } from '../../models/work-task-file.model';

@Injectable({
  providedIn: 'root'
})
export class WorkTaskService {
  private apiUrl = environment.apiUrl  + 'task';
  constructor(private http: HttpClient) {}

  getTaskSummary(workTaskId:string): Observable<WorkTaskSection[]> {
    return this.http.get<WorkTaskSection[]>(`${this.apiUrl}/${workTaskId}`);
  }

  getTaskDetail(workTaskId:string): Observable<WorkTask> {
    return this.http.get<WorkTask>(`${this.apiUrl}/${workTaskId}/detail`);
  }

  getSectionContent(workTaskId:string, sectionId: string): Observable<WorkTaskSectionElement[]> {
    return this.http.get<WorkTaskSectionElement[]>(`${this.apiUrl}/${workTaskId}/${sectionId}`);
  }

  getImage(workTaskId:string,): Observable<Blob> {
    return this.http.get(`${this.apiUrl}/${workTaskId}/image`, { responseType: 'blob' });
  }

  InitializeWorkTaskFile(workTaskId:string): Observable<WorkTaskFile> {
    return this.http.post<WorkTaskFile>(`${this.apiUrl}/${workTaskId}/fileInit`, null);
  }
}
