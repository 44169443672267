import { Component } from '@angular/core';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { ProfessionService } from '../../../../services/training/profession.service';
import { Profession } from '../../../../models/profession.model';
import { WorkTaskService } from '../../../../services/training/work-task.service';
import { FileService } from '../../../../services/file/file.service';
import { CogeoDifficultyEnum } from 'src/app/enums/cogeo-difficulty.enum';
import { CogeoApproachEnum } from 'src/app/enums/cogeo-approach.enum';
import { WorkTaskInit } from 'src/app/models/work-task-init.model';
import { HttpEventType, HttpResponse, HttpErrorResponse} from '@angular/common/http';
@Component({
  selector: 'app-import-video',
  templateUrl: './import-video.component.html',
  styleUrls: ['./import-video.component.css']
})
export class ImportVideoComponent {
  public isFileValid: boolean = false;
  public selectedFile: File | null = null;
  public isUploading: boolean = false;
  public uploadProgress: number = 0;
  private MAX_FILE_SIZE: number = 20000 * 1024 * 1024; // 20GB
  public selectedProfessionId: string = "";
  public professions: Profession[] = [];
  public fileLink: string = "";
  public difficulties = Object.entries(CogeoDifficultyEnum)
    .filter(([key, value]) => !isNaN(Number(value)))
    .map(([key, value]) => ({ label: key, value: Number(value) }));

  public approaches = Object.entries(CogeoApproachEnum)
    .filter(([key, value]) => !isNaN(Number(value)))
    .map(([key, value]) => ({ label: key, value: Number(value) }));

  public selectedApproach: number = CogeoApproachEnum["Lean"];
  public selectedDifficulty: number = CogeoDifficultyEnum["Confirmé"];
    

constructor(private router: Router, private professionService : ProfessionService, private taskService : WorkTaskService, private fileService: FileService ) { }
  
  ngOnInit(): void {
    this.professionService.getProfessions().subscribe(professions => {
      this.professions = professions;
    });
  }
  selectVideo() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'video/*';

    input.onchange = (event: any) => {
      this.fileLink = "";
      const file = event.target.files[0];
      if (file) {
        this.selectedFile = file;
        this.validateFile(file);
      }
    };

    input.click();
  }

  validateFile(file: File) { 
    if (file.type.startsWith('video/') && file.size <= this.MAX_FILE_SIZE) {
      this.isFileValid = true;
    } else {
      this.isFileValid = false;
    }
  }

  resetFile()
  {
    this.selectedFile = null;
    this.isFileValid = false;
  }

  onFileLinkInput() {
    if (this.fileLink.trim() !== "") {
      this.resetFile();
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }
  
  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer==undefined || event.dataTransfer.files.length <= 0)
     return;

    const file: File = event.dataTransfer.files[0];
    this.selectedFile = file;
    this.validateFile(file);
  }

  onCancel()
  {
    this.router.navigate(['/']);
  }

  onNext() {
    if ((!this.selectedFile || !this.isFileValid) && this.fileLink.trim() == '') {
      return;
    }
    this.professionService.getDefaultProfessionId().subscribe((professionId) => {
      this.selectedProfessionId = professionId
      this.professionService.initializeWorkTask(professionId, new WorkTaskInit(this.selectedDifficulty, this.selectedApproach)).subscribe((data) => {
        const workTaskId = data.id;
        this.taskService.InitializeWorkTaskFile(workTaskId).subscribe((dataInitializeWorkTaskFile) => {
          if (this.fileLink.trim()!='')
          {
            this.fileService.uploadWorkTaskFileFromUrl(this.fileLink.trim(), dataInitializeWorkTaskFile.id).subscribe(
              error => {
                console.error('Erreur lors de l\'upload:', error);
              }
            );
            this.moveToNextStepFromLink(this.selectedProfessionId, workTaskId,dataInitializeWorkTaskFile.id);
          }
          else if (this.selectedFile && this.isFileValid)
          {
            const uploadData = new FormData();
            uploadData.append('file', this.selectedFile!, this.selectedFile!.name);
            const file = this.selectedFile;       
            this.fileService.uploadWorkTaskFile(file!, dataInitializeWorkTaskFile.id).subscribe(
              event => {
                if (event instanceof HttpResponse) {
                  console.log('Chunk uploadé avec succès !');
                }
              },
              error => {
                console.error('Erreur lors de l\'upload:', error);
                if (error instanceof HttpErrorResponse) {
                  console.error('Erreur HTTP:', error.status, error.message);
                }
              }
            );
            this.moveToNextStepFromFile(this.selectedProfessionId, workTaskId,dataInitializeWorkTaskFile.id);
          }
        })
      });
    });

  }

  moveToNextStepFromFile(professionId: string, workTaskId: string, workTaskFileId : string) {
    if (this.selectedFile?.name)
    {
      this.router.navigate(['training/creation/play-video', professionId, workTaskId, workTaskFileId, encodeURIComponent(URL.createObjectURL(this.selectedFile))]);
    }
  }

  moveToNextStepFromLink(professionId: string, workTaskId: string, workTaskFileId: string){
    if (this.fileLink.trim()!='')
    {
      this.router.navigate(['training/creation/play-video', professionId, workTaskId, workTaskFileId, encodeURIComponent(environment.apiUrl + "videoproxy/stream-video?videoUrl="+this.fileLink.trim())]);
    }
  }
}