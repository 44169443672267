import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router) { }

  async ngOnInit(): Promise<void> {
    if (await this.authService.isAuthenticated()) {
      // Si l'utilisateur est déjà authentifié, redirigez-le vers une page appropriée
      this.router.navigate(['/']); // Par exemple, vers la page d'accueil
    } else {
      // Sinon, procédez à la connexion
      this.authService.login();
    }
  }
}