<mat-form-field>
  <mat-chip-grid #myChipGrid [(ngModel)]="mySelection">
    <mat-chip-row *ngFor="let filling of mySelection; trackBy: trackByFn" (removed)="remove(filling)">
      {{ filling }}
      <button matChipRemove  [attr.aria-label]="'Remove ' + filling">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>

    <input
      #inputField
      [matChipInputFor]="myChipGrid"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
      [matAutocomplete]="auto"
      [(ngModel)]="wordInput"
      (ngModelChange)="filterOptions($event)"
      (keydown)="handleKeydown($event)"
      placeholder="Choisissez un élément..." />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let option of filteredOptions"
                (click)="selectOption(option)"
                [disabled]="mySelection.includes(option)">
      {{ option }}
    </mat-option>
  </mat-autocomplete>

</mat-form-field>
