export class MainIndicators {
    constructor(
        public engagementRate: number,
        public engagementRateProgress: number,
        public analyzedTasksCriticityScore: number,
        public newTasks: number,
        public completionRate: number,
        public completionRateProgress: number,
        public successRate: number,
        public successRateProgress: number,
        public involvementRate: number
    ) {}
}