<div class="main-container">
    <div class="content-container">
        <div class="video-and-comments-container">
            <div [ngClass]="isVideoFinished ? 'video-container-small' : 'video-container-large'">
                <app-video-player #videoPlayerComponent [videoFileParam]="videoFile" (currentTimeBeforeChange)="handleCurrentTimeBeforeChange($event)"></app-video-player>
                <div [hidden]="isVideoFinished || !isVideoLoaded" class="buttons-container">
                    <button class="btn-round" (click)="stopSpeechRecognition()" [hidden]="!isVoiceRecognitionStart || !isRecording"><img src="/assets/pictures/recording-icon.gif" width="30px"/></button>
                    <button class="btn-round" (click)="stopSpeechRecognition()" [hidden]="!isVoiceRecognitionStart || isRecording"><i class="fas fa-ellipsis"></i></button>
                    <button class="btn-round" (click)="startSpeechRecognition()" [hidden]="isVoiceRecognitionStart"><i class="fas fa-microphone-slash"></i></button>
                </div>
                <div [hidden]="!isVideoFinished" class="buttons-container">
                    <button class="next-button" (click)="onNext()">Suivant <i class="fa-solid fa-angles-right"></i></button>
                </div>
            </div>
            <div class="comments-container" [hidden]="!isVideoFinished">
                <button class="add-comment-button" (click)="addNewComment(currentVideoTime)">
                    Ajouter un commentaire
                </button>
                <div #scrollableCommentContainer class="table-comments-container">
                    <table class="table-comments">
                        <tbody>
                            <tr *ngFor="let timeComment of commentsMod; let i = index" [ngClass]="currentVideoTime>=timeComment.startTime && currentVideoTime<timeComment.endTime ? 'comment-line-selected' : ''">
                                <div [attr.id]="timeComment.isGotoComment ? 'commentScrollTarget' : ''" class="single-comment-container">
                                    <td class="time-comment">{{ timeComment.startTime | formatTime}}</td>
                                    <td class="comment">
                                      <textarea [(ngModel)]="timeComment.comment"
                                      [disabled]="!timeComment.isEditable"
                                      [ngClass]="{'textarea-highlight': timeComment.isHighlighted}">
                                      </textarea>
                                    </td>
                                    <td class="play-comment">
                                        <button class="btn-round" (click)="goToVideoTime(timeComment.startTime)">
                                            <i class="fas fa-video"></i>
                                        </button>
                                        <button class="btn-round" style="margin-top: 5px;" *ngIf="!timeComment.isEditable" (click)="enableEditing(timeComment)">
                                          <i class="fa-solid fa-pen"></i>
                                        </button>
    
                                        <!-- Bouton validation -->
                                        <button class="btn-round" style="margin-top: 5px;" *ngIf="timeComment.isEditable" (click)="updateComment(timeComment,timeComment.comment)">
                                          <i class="fa-solid fa-check"></i>
                                        </button>
                                    </td>
                                </div>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
