import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): boolean {
    const isLoggedIn = this.authService.isAuthenticated(); // Assurez-vous que cette méthode renvoie un boolean
    if (isLoggedIn) {
      return true;
    } else {
      this.router.navigate(['/login']); // Redirige vers la page de connexion Keycloak
      return false;
    }
  }
}