import { NgModule, APP_INITIALIZER  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';  // Importation de FormsModule
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { VideoPlayerComponent } from './shared_components/video-player/video-player.component';
import { VideoCommentComponent } from './pages/training/creation/video-comment/video-comment.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ConfirmationDialogComponent } from './shared_components/confirmation-dialog/confirmation-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCommonModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { KeycloakService, KeycloakBearerInterceptor  } from 'keycloak-angular';
import { TenantSelectionComponent } from './pages/tenant-selection/tenant-selection.component';
import { AuthService } from './services/auth/auth.service';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { HTTP_INTERCEPTORS, provideHttpClient , withInterceptorsFromDi } from '@angular/common/http';
import { ImportVideoComponent } from './pages/training/creation/import-video/import-video.component';
import { PlayVideoComponent } from './pages/training/creation/play-video/play-video/play-video.component';
import { ProfessionListComponent } from './pages/training/learning/profession-list/profession-list.component';
import { TaskListComponent } from './pages/training/learning/task-list/task-list.component';
import { TaskSummaryComponent } from './pages/training/learning/task-summary/task-summary.component';
import { TaskSectionComponent } from './pages/training/learning/task-section/task-section.component';
import { TaskExplorerComponent } from './pages/training/learning/task-explorer/task-explorer.component';
import { CogeoChatComponent } from './shared_components/cogeo-chat/cogeo-chat.component';
import { VideoCogeoChatComponent } from './pages/training/creation/video-cogeo-chat/video-cogeo-chat.component';
import { AutocompleteBubblesComponent } from './shared_components/autocomplete-bubbles/autocomplete-bubbles.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { CaptureTabComponent } from './pages/home/capture-tab/capture-tab.component';
import { TransmitTabComponent } from './pages/home/transmit-tab/transmit-tab.component';
import { AnalyzeTabComponent } from './pages/home/analyze-tab/analyze-tab.component';
import { WaitingPopinComponent } from './shared_components/waiting-popin/waiting-popin.component';
import { AutocompleteBubblesCoupleValuesComponent } from './shared_components/autocomplete-bubbles-couple-values/autocomplete-bubbles-couple-values.component';
import { NgxGaugeModule } from 'ngx-gauge';
import { FormatTimePipe } from './pipes/format-time.pipe';
import { RegenerateCourseComponent } from './pages/training/creation/regenerate-course/regenerate-course.component';
import { UploadProgressComponent } from './shared_components/upload-progress/upload-progress.component';
import { GenericPopinComponent } from './shared_components/generic-popin/generic-popin.component';
import { SafeHtmlDirective } from './directives/safe-html.directive';
import { MarkdownPipe } from './pipes/markdown.pipe';
import { AuthInterceptor } from './interceptors/token-refresh.interceptor';

function initializeAuth(authService: AuthService) {
  return () => authService.init();
}

@NgModule({
  declarations: [
    AppComponent,
    VideoPlayerComponent,
    VideoCommentComponent,
    ConfirmationDialogComponent,
    TenantSelectionComponent,
    HomeComponent,
    LoginComponent,
    ImportVideoComponent,
    PlayVideoComponent,
    ProfessionListComponent,
    TaskListComponent,
    TaskSummaryComponent,
    TaskSectionComponent,
    TaskExplorerComponent,
    CogeoChatComponent,
    VideoCogeoChatComponent,
    AutocompleteBubblesComponent,
    CaptureTabComponent,
    TransmitTabComponent,
    AnalyzeTabComponent,
    WaitingPopinComponent,
    AutocompleteBubblesCoupleValuesComponent,
    FormatTimePipe,
    RegenerateCourseComponent,
    UploadProgressComponent,
    GenericPopinComponent,
    SafeHtmlDirective,
    MarkdownPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCommonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    NgxGaugeModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    provideAnimationsAsync(),
    AuthService,
    provideHttpClient(),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAuth,
      multi: true,
      deps: [AuthService]
    },
    KeycloakService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi())
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
