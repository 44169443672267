<div class="modal-overlay" *ngIf="isVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h3>{{ title }}</h3>
      </div>
      <div class="modal-body">
        <img src="{{img }}" height="100px"/>
        <ng-content></ng-content>
      </div>
      <div class="modal-footer">
        <button class="btnClose" (click)="close()">{{buttonContent}}</button>
      </div>
    </div>
  </div>