
<div class="container-fluid" (click)="selectTab('')">
  <div class="title">
    Le Beau Geste<br><br><br><br><br>  
    de
  </div>
  <div class="logo_container">
    <div class="logo_spinner"></div>
     <div class="logo">
      <img src="{{currentUserInformation.tenantLogo}}" alt="logo" class="rounded-image"/>
    </div> 
  </div>
  <div class="tabs-bottom" role="tablist">
    <div 
      class="tab large-tab capture" 
      [ngClass]="{
        'otherSelected': selectedTab !== '' && selectedTab !== 'capture',
        'active': selectedTab === 'capture'
      }"
      (click)="selectTab('capture');$event.stopPropagation()"
      role="tab" 
      [attr.aria-selected]="selectedTab === 'capture' ? 'true' : 'false'"
      tabindex="0"
      (keydown.enter)="selectTab('capture')"
      >
      <div *ngIf="selectedTab !== 'capture'">
        Vos données...
      </div>
      <app-capture-tab *ngIf="selectedTab === 'capture'"></app-capture-tab>
    </div>
    <div 
      class="tab large-tab transmit" 
      [ngClass]="{
        'transmitIfCaptureSelected': selectedTab == 'capture',
        'transmitIfAnalysisSelected': selectedTab == 'analysis',
        'active': selectedTab === 'transmit'
      }"
      (click)="selectTab('transmit');$event.stopPropagation()"
      role="tab" 
      [attr.aria-selected]="selectedTab === 'transmit' ? 'true' : 'false'"
      tabindex="0"
      (keydown.enter)="selectTab('transmit')"
      >
      <div *ngIf="selectedTab !== 'transmit'">
        ...approfondies...
      </div>
      
      <app-transmit-tab *ngIf="selectedTab === 'transmit'"></app-transmit-tab>
    </div>
    <div 
      class="tab large-tab analysis" 
      [ngClass]="{
        'otherSelected': selectedTab !== '' && selectedTab !== 'analysis',
        'active': selectedTab === 'analysis'
      }"
      (click)="selectTab('analysis');$event.stopPropagation()"
      role="tab" 
      [attr.aria-selected]="selectedTab === 'analysis' ? 'true' : 'false'"
      tabindex="0"
      (keydown.enter)="selectTab('analysis')"
      >
      <div *ngIf="selectedTab !== 'analysis'">
        ...et analysées
      </div>
      
      <app-analyze-tab *ngIf="selectedTab === 'analysis'"></app-analyze-tab>
    </div>
  </div>
</div>
