// waiting-popin.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'  // Le service est disponible globalement dans l'application
})
export class WaitingPopinService {
  private isVisibleSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private progressSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private messageSubject: BehaviorSubject<string> = new BehaviorSubject<string>('Veuillez patienter...');

  // Observable pour suivre l'état de visibilité de la popin
  isVisible$: Observable<boolean> = this.isVisibleSubject.asObservable();

  // Observable pour suivre la progression
  progress$: Observable<number> = this.progressSubject.asObservable();

  // Observable pour suivre le message
  message$: Observable<string> = this.messageSubject.asObservable();

  constructor() {}

  // Méthode pour afficher la popin
  showPopin(message: string = 'Veuillez patienter...') {
    this.messageSubject.next(message);
    this.progressSubject.next(0); // Réinitialiser la barre de progression
    this.isVisibleSubject.next(true);
  }

  // Méthode pour masquer la popin
  hidePopin() {
    this.isVisibleSubject.next(false);
  }

  // Méthode pour mettre à jour la progression
  updateProgress(value: number) {
    this.progressSubject.next(value);
  }
}
