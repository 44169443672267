<ul>
  <li 
    *ngFor="let section of sections" 
    [class.selected]="section.id === selectedSectionId" 
    (click)="selectSection(section.id)">
    <span class="circle"></span>
    <div [routerLink]="['/training/learning/profession', professionId, workTaskId, section.id]">
      {{ section.name }}
    </div>
  </li>
</ul>