import { Component } from '@angular/core';
import { HomeService } from '../../services/home/home.service';
import { UserInformation } from '../../models/user-information';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})

export class HomeComponent {
    selectedTab: string = '';
    currentUserInformation : UserInformation = new UserInformation("","","","");
    
    constructor(private homeService: HomeService) { }
    ngOnInit(): void {
      this.homeService.getUserInformations().subscribe((data) => {
       this.currentUserInformation  = data;
     });
 
    }

    selectTab(tab: string) {
      this.selectedTab = tab;
    }
  
}
